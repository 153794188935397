import { useMutation, useQuery } from "@apollo/client";
import { GET_ASSIGNMENT_QUERY } from "../../../graphql/queries";
import { IGetAssignmentQueryFilter, IGetAssignmentQueryResult } from "../../../interfaces/queries/IGetAssignmentQuery";
import { useAuth } from "../../../lib/hooks/useAuth";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import { Button, ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { IDeleteAssignmentMutationInput, IDeleteAssignmentMutationResult } from "../../../interfaces/mutations/IDeleteAssignmentMutation";
import { DELETE_ASSIGNMENT_MUTATION, TOGGLE_PUBLISH_ASSIGNMENT_MUTATION } from "../../../graphql/mutations";
import { NavLink, useHistory } from "react-router-dom";
import { GET_ASSIGNMENT_REFERENCES_ROUTE, GET_ASSIGNMENT_ROUTE } from "../../../lib/Router/routes";
import { AssignmentRouter } from "../../../lib/Router/Router";
import { GET_TEACHING_SUBJECT_ROUTE } from "../../../lib/Router/routes";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { FiTrash2 } from "react-icons/fi";
import {
  ITogglePublishAssignmentMutationInput,
  ITogglePublishAssignmentMutationResult,
} from "../../../interfaces/mutations/ITogglePublishAssignmentMutation";
import Audit from "../../_common/Audit/Audit";
import PublishStatus from "../../_common/PublishStatus/PublishStatus";

interface IEditAssignmentProps {
  id: number;
}

const EditAssignment: React.FunctionComponent<IEditAssignmentProps> = (props) => {
  const { isAdmin } = useAuth();
  const history = useHistory();

  const { loading, error, data } = useQuery<IGetAssignmentQueryResult, IGetAssignmentQueryFilter>(GET_ASSIGNMENT_QUERY, {
    variables: { filter: { id: props.id } },
  });

  const [deleteAssignment] = useMutation<IDeleteAssignmentMutationResult, IDeleteAssignmentMutationInput>(DELETE_ASSIGNMENT_MUTATION);
  const [togglePublishAssignment, { loading: togglePublishAssignmentLoading }] = useMutation<
    ITogglePublishAssignmentMutationResult,
    ITogglePublishAssignmentMutationInput
  >(TOGGLE_PUBLISH_ASSIGNMENT_MUTATION);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.assignment) return <DataNotFound />;

  const onDeleteAssignment = async (assignmentId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette oppgaven?`);

    if (deleteConfirmed) {
      await deleteAssignment({
        variables: {
          input: {
            id: assignmentId,
          },
        },
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteAssignment)) {
            handleMutationSuccess(result.data);

            history.push(GET_TEACHING_SUBJECT_ROUTE(data.assignment.teachingSubjectId));
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  const onTogglePublishAssignment = async (assignmentId: number, publish: boolean) => {
    await togglePublishAssignment({
      variables: {
        input: {
          id: assignmentId,
          publish: publish,
        },
      },
      refetchQueries: [{ query: GET_ASSIGNMENT_QUERY, variables: { filter: { id: assignmentId } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.togglePublishAssignment)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
        <h1 className="h1">{data.assignment.title}</h1>

        <div>
          {isAdmin() && (
            <ButtonToolbar className="d-flex justify-content-end">
              {data.assignment.publishStatus.isPublished ? (
                <Button
                  variant="warning"
                  size="sm"
                  className="mr-1"
                  disabled={togglePublishAssignmentLoading}
                  onClick={(event: any) => {
                    event.preventDefault();
                    onTogglePublishAssignment(data.assignment.id, !data.assignment.publishStatus.isPublished);
                  }}>
                  {togglePublishAssignmentLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Avpubliserer...</span>
                    </>
                  ) : (
                    <span> Avpubliser</span>
                  )}
                </Button>
              ) : (
                <Button
                  variant="success"
                  size="sm"
                  className="mr-1"
                  disabled={togglePublishAssignmentLoading}
                  onClick={(event: any) => {
                    event.preventDefault();
                    onTogglePublishAssignment(data.assignment.id, !data.assignment.publishStatus.isPublished);
                  }}>
                  {togglePublishAssignmentLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Publiserer...</span>
                    </>
                  ) : (
                    <span> Publiser</span>
                  )}
                </Button>
              )}
              <DropdownButton size="sm" variant="secondary" alignRight id="dropdown-basic-button" title="Flere valg">
                <Dropdown.Item
                  className="text-danger"
                  eventKey="1"
                  onClick={() => {
                    onDeleteAssignment(data.assignment.id);
                  }}>
                  <FiTrash2 /> Slett oppgave
                </Dropdown.Item>
              </DropdownButton>
            </ButtonToolbar>
          )}

          <Audit audit={data.assignment.audit}></Audit>
          <PublishStatus publishStatus={data.assignment.publishStatus}></PublishStatus>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <NavLink to={GET_ASSIGNMENT_ROUTE(data.assignment.id)} exact className="nav-link" activeClassName="active bg-white">
                Generelt
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={GET_ASSIGNMENT_REFERENCES_ROUTE(data.assignment.id)}
                exact
                className="nav-link"
                activeClassName="active bg-white">
                Referanser
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <AssignmentRouter assignment={data.assignment}></AssignmentRouter>
        </div>
      </div>
    </div>
  );
};

export default EditAssignment;
