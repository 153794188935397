import React from "react";
import { FiPlusCircle } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { SearchFilterObjectTypeEnum } from "../../../interfaces/algolia/ISearchResultHit";
import { ROUTES } from "../../../lib/Router/routes";
import Search from "../../_common/Search/Search";

const References: React.FunctionComponent = (props) => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Referanse</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <NavLink to={ROUTES.AUTHORIZED.REFERENCE_CREATE_ROUTE} className="btn btn-sm btn-outline-dark" role="button" exact>
            <FiPlusCircle /> Legg til
          </NavLink>
        </div>
      </div>

      <Search objectType={SearchFilterObjectTypeEnum.Reference} activeReferences></Search>
    </div>
  );
};

export default References;
