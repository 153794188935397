import { IUser, UserRolesEnum } from "../interfaces/domain/IUser";
import { GetAuthorizedRoutes } from "../lib/Router/routes";

export const getUserDisplayName = (user: IUser) => {
  return user.fullName ? user.fullName : user.userName;
};

export const getUserRoles = (user: IUser) => {
  if (user && user.roles) {
    return user.roles;
  } else {
    return undefined;
  }
};

export const userHasRole = (user: IUser, role: UserRolesEnum) => {
  const currentRoles = getUserRoles(user);

  if (currentRoles) {
    return currentRoles.includes(role);
  }

  return false;
};

export const userIsAuthorized = (user: IUser) => {
  return userIsAdmin(user) || userIsModerator(user) || userIsEducation(user);
};

export const userIsAdmin = (user: IUser) => {
  return userHasRole(user, UserRolesEnum.ADMIN);
};

export const userIsModerator = (user: IUser) => {
  return userHasRole(user, UserRolesEnum.MODERATOR);
};

export const userIsEducation = (user: IUser) => {
  return userHasRole(user, UserRolesEnum.EDUCATION);
};


export const userHasRoutePermission = (user: IUser, route: string) => {
  if (userIsAdmin(user)) {
    return true;
  } else if (userIsModerator(user) || userIsEducation(user)) {
    const authorizedRoutes = GetAuthorizedRoutes();

    return authorizedRoutes.includes(route);
  }

  return false;
};
