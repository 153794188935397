import "instantsearch.css/themes/algolia.css";
import React, { useState, useEffect } from "react";
import { Configure, InstantSearch, Pagination, SearchBox } from "react-instantsearch-dom";
import { ENVIRONMENT_ALGOLIA_INDEX_NAME } from "../../../helpers/environmentHelpers";
import { SearchFilterObjectTypeEnum } from "../../../interfaces/algolia/ISearchResultHit";
import { IPageSource } from "../../../interfaces/domain/common/ISearch";
import searchClient from "../../../lib/algolia/searchClient";
import SearchFilter from "./SearchFilter/SearchFilter";
import SearchResult from "./SearchResult";

interface SearchProps {
  objectType?: SearchFilterObjectTypeEnum;
  pageSource?: IPageSource;
  hideSearchFilter?: boolean;
  hideSearchBox?: boolean;
  hidePagination?: boolean;
  defaultFilters?: string;
  optionalFacets?: string;
  activeReferences?: boolean;
}

const Search: React.FunctionComponent<SearchProps> = (props) => {
  let [refreshAlgoliaCache, setRefreshAlgoliaCache] = useState(false);

  const selectedFilters = props.defaultFilters ? props.defaultFilters : props.objectType;
  const selectedFacets = props.optionalFacets ? [props.optionalFacets] : ["*"];

  useEffect(() => {
    const timer = setTimeout(() => {
      setRefreshAlgoliaCache(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, [refreshAlgoliaCache]);

  return (
    <div className="Search">
      <InstantSearch searchClient={searchClient} indexName={ENVIRONMENT_ALGOLIA_INDEX_NAME} refresh={refreshAlgoliaCache}>
        <Configure hitsPerPage={25} filters={selectedFilters} facetFilters={selectedFacets} />

        {!props.hideSearchBox && (
          <div className="mb-3 Search__SearchBox">
            <SearchBox
              searchAsYouType={false}
              autoFocus
              translations={{
                submitTitle: "Søk",
                resetTitle: "Nullstill",
                placeholder: "Søk...",
              }}
            />
          </div>
        )}
        {!props.hideSearchFilter && (
          <div className="mb-3">
            <SearchFilter objectType={props.objectType} activeReferences={props.activeReferences} />
          </div>
        )}

        <SearchResult pageSource={props.pageSource} hideIcon />

        {!props.hidePagination && (
          <div className="mt-3 Search__Pagination">
            <Pagination showLast={true} />
          </div>
        )}
      </InstantSearch>
    </div>
  );
};

export default Search;
