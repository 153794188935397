import React, { createContext, useContext, useEffect, useState } from "react";
import { userHasRoutePermission, userIsAdmin, userIsAuthorized, userIsModerator, userIsEducation } from "../../helpers/userHelpers";
import { IAuthenticatedUser } from "../../interfaces/domain/IUser";
import { deleteInfoCookie, getAuthenticatedUserFromInfoCookie } from "../AuthenticationService";

interface IAuthContext {
  currentUser: IAuthenticatedUser | undefined;
  isSignedIn: boolean;
  isAuthorized: () => boolean;
  isAdmin: () => boolean;
  isEducation: () => boolean;
  isModerator: () => boolean;
  canVisit: (route: string) => boolean;
  signIn: (user: IAuthenticatedUser) => void;
  signOut: () => void;
}

const defaultValue: any = {};
const AuthContext = createContext<IAuthContext>(defaultValue);

export const AuthProvider = (props: any) => {
  const auth = useProvideAuth();

  return <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  return authContext;
};

const useProvideAuth = (): IAuthContext => {
  const [, setSignedIn] = useState<boolean>(false);
  const currentUser = getAuthenticatedUserFromInfoCookie();

  useEffect(() => {
    if (currentUser) {
      signIn(currentUser);
    }
  });

  const signIn = (user: IAuthenticatedUser) => {
    if (userIsAuthorized(user)) {
      setSignedIn(true);
    } else {
      signOut();
    }
  };

  const signOut = () => {
    deleteInfoCookie();
    setSignedIn(false);
  };

  const isAuthorized = () => {
    return !!currentUser && userIsAuthorized(currentUser);
  };

  const isAdmin = () => {
    return !!currentUser && userIsAdmin(currentUser);
  };

  const isEducation = () => {
    return !!currentUser && userIsEducation(currentUser);
  };

  const isModerator = () => {
    return !!currentUser && userIsModerator(currentUser);
  };

  const canVisit = (route: string) => {
    return !!currentUser && userHasRoutePermission(currentUser, route);
  };

  const isSignedIn = currentUser ? true : false;

  return {
    currentUser,
    isSignedIn,
    isAuthorized,
    isAdmin,
    isModerator,
    isEducation,
    canVisit,
    signIn,
    signOut
  };
};
