import { useForm } from "react-hook-form";
import { ITeachingModule } from "../../../interfaces/domain/ITeachingModule";
import { ITeachingModuleForm } from "../../../interfaces/forms/ITeachingModuleForm";
import { teachingModuleSchema } from "../../../helpers/validators/teachingModuleSchema";
import { Prompt } from "react-router-dom";
import FieldError from "../../_common/Form/FieldError/FieldError";
import WYSIWYGEditor from "../../_common/Form/WYSIWYGEditor/WYSIWYGEditor";
import { useMutation } from "@apollo/client";
import { UPDATE_TEACHING_MODULE_MUTATION } from "../../../graphql/mutations";
import {
  IUpdateTeachingModuleMutationInput,
  IUpdateTeachingModuleMutationResult,
} from "../../../interfaces/mutations/IUpdateTeachingModuleMutation";
import { GET_TEACHING_CATEGORY_QUERY } from "../../../graphql/queries";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";

interface IEditTeachingModuleFormProps {
  teachingModule: ITeachingModule;
}

const EditTeachingModuleForm: React.FunctionComponent<IEditTeachingModuleFormProps> = (props) => {
  const { register, handleSubmit, errors, formState, getValues, setValue } = useForm<ITeachingModuleForm>({
    validationSchema: teachingModuleSchema,
    mode: "onChange",
    defaultValues: {
      title: props.teachingModule.title,
      lead: props.teachingModule.lead,
      body: props.teachingModule.body,
      videoUrl: props.teachingModule.videoUrl,
    },
  });

  const [updateTeachingModule] = useMutation<IUpdateTeachingModuleMutationResult, IUpdateTeachingModuleMutationInput>(
    UPDATE_TEACHING_MODULE_MUTATION
  );
  const onSubmit = handleSubmit(async (formValues) => {
    await updateTeachingModule({
      variables: {
        input: {
          id: props.teachingModule.id,
          title: formValues.title,
          lead: formValues.lead,
          body: formValues.body,
          videoUrl: formValues.videoUrl,
        },
      },
      refetchQueries: [{ query: GET_TEACHING_CATEGORY_QUERY }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateTeachingModule)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Prompt
        when={formState.dirty && !formState.isSubmitted}
        message="Du har endringer som ikke er lagret. Er du sikker på du vil fortsette? Endringene vil gå tapt hvis du fortsetter uten å lagre.
        Trykk OK for å gå videre uten å lagre, eller trykk Avbryt for å gå tilbake."
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="form-group">
                <label className="required-field" htmlFor="title">
                  Tittel
                </label>
                <input type="text" name="title" ref={register} className={`form-control ${errors.title ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.title}></FieldError>
              </div>

              <div className="form-group">
                <label htmlFor="videoUrl">Video Url</label>
                <input type="text" name="videoUrl" ref={register} className={`form-control ${errors.videoUrl ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.videoUrl}></FieldError>
              </div>

              <div className="form-group">
                <label htmlFor="lead">Ingress</label>
                <textarea name="lead" ref={register} className={`form-control ${errors.lead ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.lead}></FieldError>
              </div>

              <WYSIWYGEditor
                fieldName="body"
                label="Innhold"
                register={register}
                errors={errors}
                setValue={setValue}
                currentValue={getValues().body}
                initialValue={props.teachingModule?.body || ""}
              />
            </div>

            <div className="card-footer">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
                  {formState.isSubmitting ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Lagrer...</span>
                    </>
                  ) : (
                    <span>Lagre</span>
                  )}
                </button>

                <div>
                  <small className="text-muted">Innholdet vil bli publisert</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditTeachingModuleForm;
