import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect } from "react";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";

import FieldValidationError from "../FieldError/FieldError";
import { ENVIRONMENT_TINY_MCE_API_KEY } from "../../../../helpers/environmentHelpers";

interface WYSIWYGEditorProps {
  fieldName: string;
  label?: string;
  register: any;
  setValue: any;
  errors: NestDataObject<any, FieldError>;
  currentValue?: string;
  size?: "small" | "medium" | "large";
  initialValue?: string;
}

const WYSIWYGEditor: React.FunctionComponent<WYSIWYGEditorProps> = (props) => {
  useEffect(() => {
    props.register({ name: props.fieldName });
  }, [props]);

  const handleEditorChange = (e: any) => {
    const content = e.target.getContent();
    props.setValue(props.fieldName, content, true);
  };

  return (
    <div className="form-group">
      {props.label && <label htmlFor={props.fieldName}>{props.label}</label>}

      <Editor
        apiKey={ENVIRONMENT_TINY_MCE_API_KEY}
        initialValue={props.initialValue}
        init={{
          height: props.size === "medium" ? 350 : props.size === "small" ? 200 : 500,
          menubar: false,
          plugins: [
            "link paste advlist autolink lists image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table code help wordcount",
          ],
          toolbar:
            "undo redo | styleselect | bold italic backcolor | fontsizeselect | alignleft aligncenter alignright alignjustify | link | bullist numlist outdent indent | removeformat | help | superscript subscript",
          valid_elements:
            "@[id|class|style|title|dir<ltr?rtl|lang|xml::lang|onclick|ondblclick|" +
            "onmousedown|onmouseup|onmouseover|onmousemove|onmouseout|onkeypress|" +
            "onkeydown|onkeyup],a[rel|rev|charset|hreflang|tabindex|accesskey|type|" +
            "name|href|target|title|class|onfocus|onblur],strong/b,em/i,strike,u," +
            "#p,-ol[type|compact],-ul[type|compact],-li,br,img[longdesc|usemap|" +
            "src|border|alt=|title|hspace|vspace|width|height|align],-sub,-sup," +
            "-blockquote,-table[border=0|cellspacing|cellpadding|width|frame|rules|" +
            "height|align|summary|bgcolor|background|bordercolor],-tr[rowspan|width|" +
            "height|align|valign|bgcolor|background|bordercolor],tbody,thead,tfoot," +
            "#td[colspan|rowspan|width|height|align|valign|bgcolor|background|bordercolor" +
            "|scope],#th[colspan|rowspan|width|height|align|valign|scope],caption,-div," +
            "-span,-code,-pre,address,-h1,-h2,-h3,-h4,-h5,-h6,hr[size|noshade],-font[face" +
            "|size|color],dd,dl,dt,cite,abbr,acronym,del[datetime|cite],ins[datetime|cite]," +
            "object[classid|width|height|codebase|*],param[name|value|_value],embed[type|width" +
            "|height|src|*],script[src|type],map[name],area[shape|coords|href|alt|target],bdo," +
            "button,col[align|char|charoff|span|valign|width],colgroup[align|char|charoff|span|" +
            "valign|width],dfn,fieldset,form[action|accept|accept-charset|enctype|method]," +
            "input[accept|alt|checked|disabled|maxlength|name|readonly|size|src|type|value]," +
            "kbd,label[for],legend,noscript,optgroup[label|disabled],option[disabled|label|selected|value]," +
            "q[cite],samp,select[disabled|multiple|name|size],small," +
            "textarea[cols|rows|disabled|name|readonly],tt,var,big",
        }}
        onChange={handleEditorChange}
      />

      <FieldValidationError error={props.errors[props.fieldName]}></FieldValidationError>
    </div>
  );
};

export default WYSIWYGEditor;
