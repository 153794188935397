import { useMutation } from "@apollo/client";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { UPDATE_HISTORY_MUTATION } from "../../../../graphql/mutations";
import { GET_HISTORY_QUERY } from "../../../../graphql/queries";
import { historySchema } from "../../../../helpers/validators/historySchema";
import { IHistory } from "../../../../interfaces/domain/IHistory";
import { IHistoryForm } from "../../../../interfaces/forms/IHistoryForm";
import { IUpdateHistoryMutationInput, IUpdateHistoryMutationResult } from "../../../../interfaces/mutations/IUpdateHistoryMutation";
import FieldError from "../../../_common/Form/FieldError/FieldError";
import WYSIWYGEditor from "../../../_common/Form/WYSIWYGEditor/WYSIWYGEditor";
import FormSubTitle from "../../../_common/Form/FormSubTitle/FormSubTitle";
import PrisonCampsSelectInput from "../../../PrisonCamp/PrisonCampsSelectInput/PrisonCampsSelectInput";
import PersonsSelectInput from "../../../Person/PersonsSelectInput/PersonsSelectInput";
import { Prompt } from "react-router-dom";
import { mapAttachmentToSelectOption, mapToSelectOption } from "../../../../helpers/selectHelpers";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../../helpers/formHelpers";
import { IPrisonCampOption } from "../../../../interfaces/domain/IPrisonCamp";
import { IPersonOption } from "../../../../interfaces/domain/IPerson";
import { GetPersonFullName } from "../../../../helpers/personHelpers";
import Select from "react-select";

interface IEditHistoryFormProps {
  history: IHistory;
}

const EditHistoryForm: React.FunctionComponent<IEditHistoryFormProps> = (props) => {
  const { register, handleSubmit, errors, formState, getValues, setValue, control } = useForm<IHistoryForm>({
    validationSchema: historySchema,
    mode: "onChange",
    defaultValues: {
      title: props.history.title,
      slug: props.history.slug,
      description: props.history.description,
      text: props.history.text,
      author: props.history.author,
      authorEmail: props.history.authorEmail,
      mainImage: props.history.mainImage ? mapAttachmentToSelectOption(props.history.mainImage) : undefined,
      prisonCamps: props.history.prisonCamps.map((prisonCamp) => {
        return mapToSelectOption(prisonCamp.id, prisonCamp.name);
      }),
      persons: props.history.persons.map((person) => {
        return mapToSelectOption(person.id, GetPersonFullName(person.firstName, person.middleName, person.lastName));
      }),
    },
  });

  const [updateHistory] = useMutation<IUpdateHistoryMutationResult, IUpdateHistoryMutationInput>(UPDATE_HISTORY_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await updateHistory({
      variables: {
        input: {
          id: props.history.id,
          title: formValues.title,
          slug: formValues.slug,
          description: formValues.description,
          text: formValues.text,
          author: formValues.author,
          authorEmail: formValues.authorEmail,
          mainImageId: formValues.mainImage && formValues.mainImage.value ? Number(formValues.mainImage.value) : undefined,
          persons: formValues.persons?.map((person) => {
            return { id: person.value, firstName: person.label } as IPersonOption;
          }),
          prisonCamps: formValues.prisonCamps?.map((prisonCamp) => {
            return { id: prisonCamp.value, name: prisonCamp.label } as IPrisonCampOption;
          }),
        },
      },
      refetchQueries: [{ query: GET_HISTORY_QUERY, variables: { filter: { id: props.history.id } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateHistory)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Prompt
        when={formState.dirty && !formState.isSubmitted}
        message="Du har endringer som ikke er lagret. Er du sikker på du vil fortsette? Endringene vil gå tapt hvis du fortsetter uten å lagre.
        Trykk OK for å gå videre uten å lagre, eller trykk Avbryt for å gå tilbake."
      />
      <div className="row">
        <div className="col-lg-8">
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="form-group">
                <label className="required-field" htmlFor="title">
                  Tittel
                </label>
                <input type="text" name="title" ref={register} className={`form-control ${errors.title ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.title}></FieldError>
              </div>

              <div className="form-group">
                <label className="required-field" htmlFor="slug">
                  Unik URL-vennlig tittel
                </label>
                <input type="text" name="slug" ref={register} className={`form-control ${errors.slug ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.slug}></FieldError>
              </div>
              <div className="form-group">
                <label className="" htmlFor="author">
                  Forfatter
                </label>
                <input type="text" name="author" ref={register} className={`form-control ${errors.author ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.author}></FieldError>
              </div>
              <div className="form-group">
                <label className="" htmlFor="authorEmail">
                  Epost til forfatter
                </label>
                <input
                  type="email"
                  name="authorEmail"
                  ref={register}
                  placeholder="E-post"
                  autoComplete="off"
                  className={`form-control ${errors.authorEmail ? "is-invalid" : "valid"}`}
                />

                <FieldError error={errors.authorEmail}></FieldError>
              </div>

              <div className="form-group">
                <label htmlFor="description">Ingress</label>
                <textarea name="description" ref={register} className={`form-control ${errors.description ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.description}></FieldError>
              </div>

              <WYSIWYGEditor
                fieldName="text"
                label="Innhold"
                register={register}
                errors={errors}
                setValue={setValue}
                currentValue={getValues().text}
                initialValue={props.history?.text || ""}
              />
            </div>
            <div className="card-footer">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
                  {formState.isSubmitting ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Lagrer...</span>
                    </>
                  ) : (
                    <span>Lagre</span>
                  )}
                </button>
                <div>
                  <small className="text-muted">Innholdet vil bli publisert</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Hovedbilde</FormSubTitle>
              <div className="form-row">
                <div className="col-md-12">
                  {props.history.mainImage || props.history.availableImageAttachments.length > 0 ? (
                    <div className="form-group">
                      <Controller
                        as={Select}
                        control={control}
                        name="mainImage"
                        value={getValues().mainImage}
                        options={props.history.availableImageAttachments.map((attachment) => mapAttachmentToSelectOption(attachment))}
                        placeholder="Velg..."
                        isClearable
                      />
                    </div>
                  ) : (
                    <span className="text-muted">Du må først legge til en referanse med et bildevedlegg.</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="card shadow-sm">
            <div className="card-body">
              <FormSubTitle>Tilknyttede personer</FormSubTitle>
              <PersonsSelectInput fieldName="persons" control={control} errors={errors} currentValues={getValues().persons} />

              <FormSubTitle>Tilknyttede fangesteder</FormSubTitle>
              <PrisonCampsSelectInput fieldName="prisonCamps" control={control} errors={errors} currentValues={getValues().prisonCamps} />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditHistoryForm;
