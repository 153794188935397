import { useMutation } from "@apollo/client";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import Select from "react-select";
import { UPDATE_REFERENCE_MUTATION } from "../../../graphql/mutations";
import { GetReadableReferenceCategoryName, GetReferenceCategoryEnumAsSelectOptions } from "../../../helpers/enumHelpers";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { mapToSelectOption } from "../../../helpers/selectHelpers";
import { referenceSchema } from "../../../helpers/validators/referenceSchema";
import { IReferenceCategoryEnum } from "../../../interfaces/domain/enums/IReferenceCategoryEnum";
import { IReference } from "../../../interfaces/domain/IReference";
import { IReferenceForm } from "../../../interfaces/forms/IReferenceForm";
import { IUpdateReferenceMutationInput, IUpdateReferenceMutationResult } from "../../../interfaces/mutations/IUpdateReferenceMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import WYSIWYGEditor from "../../_common/Form/WYSIWYGEditor/WYSIWYGEditor";

interface IEditReferenceFormProps {
  reference: IReference;
}

const EditReferenceForm: React.FunctionComponent<IEditReferenceFormProps> = (props) => {
  const { register, watch, handleSubmit, errors, formState, getValues, control, setValue } = useForm<IReferenceForm>({
    validationSchema: referenceSchema,
    mode: "onChange",
    defaultValues: {
      title: props.reference.title,
      archiveIdentification: props.reference.archiveIdentification,
      source: props.reference.source,
      url: props.reference.url,
      description: props.reference.description,
      internalRemark: props.reference.internalRemark,
      category: mapToSelectOption(props.reference.category, GetReadableReferenceCategoryName(props.reference.category)),
      showEmbedded: props.reference.showEmbedded,
    },
  });

  const watchCategory = watch("category");
  const [updateReference] = useMutation<IUpdateReferenceMutationResult, IUpdateReferenceMutationInput>(UPDATE_REFERENCE_MUTATION);

  React.useEffect(() => {
    if (watchCategory?.value !== IReferenceCategoryEnum.VIDEO && watchCategory?.value !== IReferenceCategoryEnum.SOUND){
      setValue("showEmbedded", false);
    }
  },[watchCategory, setValue])

  const onSubmit = handleSubmit(async (formValues) => {
    await updateReference({
      variables: {
        input: {
          id: props.reference.id,
          title: formValues.title,
          archiveIdentification: formValues.archiveIdentification,
          source: formValues.source,
          url: formValues.url,
          description: formValues.description,
          internalRemark: formValues.internalRemark,
          category: formValues.category.value as IReferenceCategoryEnum,
          showEmbedded: formValues.showEmbedded,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateReference)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Prompt
        when={formState.dirty && !formState.isSubmitted}
        message="Du har endringer som ikke er lagret. Er du sikker på du vil fortsette? Endringene vil gå tapt hvis du fortsetter uten å lagre.
        Trykk OK for å gå videre uten å lagre, eller trykk Avbryt for å gå tilbake."
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="form-group">
                <label className="required-field" htmlFor="category">
                  Kategori
                </label>

                <Controller
                  as={Select}
                  control={control}
                  name="category"
                  value={getValues().category}
                  options={GetReferenceCategoryEnumAsSelectOptions()}
                  placeholder="Velg..."
                />
              </div>
              {(watchCategory?.value === IReferenceCategoryEnum.VIDEO || watchCategory?.value === IReferenceCategoryEnum.SOUND) && (
                <div className="form-group">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      placeholder="showEmbedded"
                      id="showEmbedded"
                      name="showEmbedded"
                      ref={register}
                    />
                    <label className="form-check-label" htmlFor="showEmbedded">
                      Vise avspiller? (Gjelder kun Digitalarkivet, Youtube, Vimeo)
                    </label>
                    <small className="form-text text-muted">
                      For å vise avspiller må Nettadresse (URL) være i et av følgende format: 'https://media.digitalarkivet.no/media/',
                      'https://youtu.be/', 'https://www.youtube.com/watch?', 'https://vimeo.com/'
                    </small>
                  </div>
                </div>
              )}

              <div className="form-group">
                <label htmlFor="source">Arkivskaper og -institusjon / Forfatter og utgiver / Fotograf</label>
                <input type="text" name="source" ref={register} className={`form-control ${errors.source ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.source}></FieldError>
              </div>
              <div className="form-group">
                <label className="required-field" htmlFor="title">
                  Tittel
                </label>
                <input type="text" name="title" ref={register} className={`form-control ${errors.title ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.title}></FieldError>
              </div>

              <WYSIWYGEditor
                fieldName="description"
                label="Beskrivelse"
                register={register}
                errors={errors}
                setValue={setValue}
                currentValue={getValues().description}
                initialValue={props.reference?.description || ""}
                size="small"
              />

              <div className="form-group">
                <label htmlFor="archiveIdentification">Arkivreferanse / ISBN</label>
                <input
                  type="text"
                  name="archiveIdentification"
                  ref={register}
                  className={`form-control ${errors.archiveIdentification ? "is-invalid" : "valid"}`}
                />

                <FieldError error={errors.archiveIdentification}></FieldError>
              </div>

              <div className="form-group">
                <label htmlFor="url">Nettadresse (URL)</label>
                <input type="text" name="url" ref={register} className={`form-control ${errors.url ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.url}></FieldError>
              </div>

              <WYSIWYGEditor
                fieldName="internalRemark"
                label="Notat (vises kun internt)"
                register={register}
                errors={errors}
                setValue={setValue}
                currentValue={getValues().internalRemark}
                initialValue={props.reference.internalRemark || ""}
                size="small"
              />
            </div>

            <div className="card-footer">
              <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
                {formState.isSubmitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span> Lagrer...</span>
                  </>
                ) : (
                  <span>Lagre</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditReferenceForm;
